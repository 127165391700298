@import "buttons";

div.posts-pagination {
  display: flex;
  padding: 50px 0;
  align-content: center;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex-wrap: wrap;

  .pagination-item a {
    @extend .button-secondary
  }
}
