@import "../dimens";
@import "../colors";

.no-bottom-padding {
  padding-bottom: 0 !important;
}

.round-corners {
  border-radius: $border-radius;

  > img {
    border-radius: $border-radius;
  }
}
