@import "colors";
@import "dimens";
@import "z-index";
@import "utils";

@include desktop() {

  .top {
    background: $color-base;
  }

  .main-navigation {
    max-width: $width-standard-container + (2 * $padding-standard-container);
    padding: 0 $padding-standard-container;
    margin: 0 auto;
    display: flex;
    gap: 24px;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-between;

    .main-navigation-bottom {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      flex: 1;
    }

    .top-menu-wrapper {
      flex: 1;
      margin-left: 48px;
    }

    ul.main {
      display: inline-flex;
      gap: 40px;
      padding: 0;
      margin: 0;
      list-style: none;
      font-size: 16px;

      & > li.menu-item {
        display: inline-flex;
        height: 80px;
        font-weight: bold;
        position: relative;

        & > a {
          cursor: pointer;
          color: $color-text-inverted-standard;
          display: flex;
          align-content: center;
          align-items: center;
          height: 100%;
          padding: 0 5px;
          position: relative;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &::after {
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 100%;
            height: 4px;
            content: "";
            background: $color-accent;
            transition: transform .2s ease .05s;
            transform: translateX(-50%) scaleX(0);
          }
        }

        &:hover > a::after,
        &:focus > a::after {
          transform: translateX(-50%) scaleX(1);
        }

        &:hover .sub-menu,
        .sub-menu:hover {
          visibility: visible;
          opacity: 1;
        }

        .sub-menu {
          z-index: $z-index-menu;
          visibility: hidden;
          opacity: 0;
          transition: all 0.3s ease;
          position: absolute;
          top: calc(100% + 20px);
          min-width: 200px;
          white-space: nowrap;
          padding: 25px 0;
          list-style: none;
          background: $color-background-desktop-submenu;
          border-radius: 6px;
          left: 50%;
          transform: translateX(-50%);
          box-shadow: 0 0 40px 0 rgba(0, 0, 0, .2);

          $arrow-size: 20px;

          &::before {
            bottom: 100%;
            left: 50%;
            border-radius: 2px;
            content: "";
            background: $color-background-desktop-submenu;
            height: $arrow-size;
            width: $arrow-size;
            position: absolute;
            pointer-events: none;
            transform: translateX(-50%) translateY(calc(50% + 2px)) rotate(45deg);
          }

          &::after {
            position: absolute;
            display: block;
            content: "";
            top: -25px;
            height: 25px;
            left: 0;
            right: 0;
          }

          a {
            color: $color-text-standard;
            display: flex;
            font-weight: normal;
            align-content: center;
            align-items: center;
            height: 100%;
            padding: 8px 32px;

            &:hover {
              background: #efefef;
            }
          }
        }
      }
    }
  }
}

@include mobile() {

  body.top-menu-expanded {

    overflow: hidden;

    .top {
      .main-navigation {
        .main-navigation-bottom {
          transform: translateX(0);
        }
      }
    }
  }

  body.customize-support .top {
    top: 46px;
  }

  .top {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: $z-index-menu;
    font-size: 18px;
    pointer-events: none;

    .main-navigation {
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;
      justify-content: space-between;
      height: 100%;

      .main-navigation-top {
        width: 100%;
        border-bottom: 1px solid $color-top-menu-separator;
        text-align: center;
        padding: 16px;
        background: $color-base;
        pointer-events: all;
        position: relative;
      }

      .main-navigation-bottom {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        flex: 1;
        overflow-y: auto;
        background: $color-base;
        pointer-events: all;
        transform: translateX(-100%);
        transition: all ease .3s;
      }

      .download-app-btn-container {
        display: flex;
        width: 100%;
        padding: 40px 16px;
      }

      .toggle-menu-wrapper {
        position: absolute;
        display: flex;
        top: 16px;
        bottom: 16px;

        .toggle-menu {
          padding: 4px;
        }
      }

      .top-menu-wrapper {
        flex: 1;
        width: 100%;

        ul.main {
          list-style: none;
          margin: 0;
          padding: 0;

          & > li {
            margin: 0;
            padding: 0;
            position: relative;

            &.menu-item-has-children > a::after {
              content: '';
              position: absolute;
              background: url("../img/icons/collapse.svg") no-repeat center center;
              width: 30px;
              height: 30px;
              right: 16px;
              transition: all ease .3s;
              transform: rotate(180deg);
            }

            &.menu-item-has-children.expanded > a::after {
              transform: rotate(0deg);
            }

            & > a {
              color: $color-top-menu-font;
              display: block;
              padding: 16px;
              border-bottom: 1px solid $color-top-menu-separator;
              background: $color-top-menu-background;
            }

            &.expanded ul.sub-menu {
              margin: 0;
            }
          }

          .sub-menu-wrapper {
            overflow: hidden;
          }

          ul.sub-menu {
            list-style: none;
            margin: -100% 0 0 0;
            transition: all ease .3s;
            padding: 0;

            & > li {
              margin: 0;
              padding: 0;

              & > a {
                display: block;
                padding: 16px;
                border-bottom: 1px solid $color-top-menu-submenu-separator;
                background: $color-top-menu-submenu-background;
                color: $color-top-menu-submenu-font;
              }
            }
          }
        }
      }
    }
  }
}
