@import "../utils";

.main-content {
  div.post-details {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;

    @include desktop {
      margin-top: 44px;
      margin-bottom: 44px;
    }

    @include mobile {
      margin-top: 32px;
      margin-bottom: 32px;

      .post-details-sharing {
        margin-top: 16px;

        .sharing-box {
          justify-content: flex-start;
        }
      }
    }

    &.bottom {
      margin-bottom: 80px;
    }
  }
}
