@import "colors";
@import "dimens";
@import "utils";

* {
  box-sizing: border-box;
}

body {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 0;
  margin: 0;
  color: $color-text-standard;
  font-family: Lato, sans-serif;
  font-size: 18px;
  font-weight: 400;
  background-color: $color-base;
}

a {
  color: $color-accent;
  text-decoration: none;

  &:hover {
    color: lighten($color-accent, 20%);
  }
}

.container {
  max-width: $width-standard-container + (2 * $padding-standard-container);
  padding: 0 $padding-standard-container;
  margin: 0 auto;
}

.main-content {
  background-color: #fff;
}

::selection {
  background: rgba($color-accent, .4);
}

.noscroll {
  overflow: hidden;
}

.notransitions *,
.notransitions *::after,
.notransitions *::before {
  transition: none !important;
  animation: none !important;
}

@include desktop {
  .only-mobile {
    display: none !important;
  }
}

@include mobile {
  .only-desktop {
    display: none !important;
  }

  .noscroll-mobile {
    overflow: hidden;
  }
}
