@import "../dimens";
@import "../colors";

.main-content {

  .wp-block-columns {
    margin-bottom: 0;
  }

  $width-small: $width-standard-container * .7;
  $width-semiwide: $width-standard-container * .83;
  $width-wide: $width-standard-container;

  .post-thumbnail-block {
    border-bottom: 6px solid $color-accent;
  }

  > * {
    max-width: $width-small;
    margin: 0 auto;
  }

  .alignnarrow {
    max-width: $width-small;
    margin: 0 auto;
  }

  .alignsemiwide {
    max-width: $width-semiwide;
    margin: 0 auto;
  }

  .alignwide {
    max-width: $width-wide;
    margin: 0 auto;
  }

  .alignfull {
    max-width: initial;
    margin: 0;
  }

  .wp-block-cover .wp-block-cover__inner-container {
    width: $width-wide;
  }

  p {
    font-size: 18px;
    font-stretch: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #222;
    padding-bottom: 24px;
    margin: 0 auto;

    &.no-spacing {
      padding-bottom: 0;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 auto;
    font-weight: 600;
    padding-top: 24px;
    padding-bottom: 24px;

    &.no-spacing {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  ul {
    list-style: none;
    padding: 0 0 0 1em;
    margin-bottom: 24px;

    li {
      padding: 10px 0;

      &::before {
        content: "\2022";
        color: $color-accent;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    }
  }

  .post-categories {
    margin: 40px auto;
  }

  blockquote {
    background-color: #f9f9f9;
    position: relative;
    padding: 36px;
    color: $color-text-light;
    font-size: 22px;
    font-stretch: normal;
    font-style: italic;
    line-height: 1.45;
    letter-spacing: normal;
    border-left: 6px solid $color-accent;
    margin-bottom: 24px;

    p {
      margin: 0;
      padding: 0;
    }
  }
}
