@import "colors";

@mixin register_wp_color($color-name, $color-value) {

  .main-content {
    .has-#{$color-name}-background-color {
      background-color: $color-value;
    }

    .has-#{$color-name}-color {
      color: $color-value;
    }

    .has-#{$color-name}-border-color {
      border-color: $color-value;
    }
  }
}

@include register_wp_color("vanway-gray", $color-text-light)
@include register_wp_color("vanway-light-gray", $color-text-inverted-little-dark)
@include register_wp_color("vanway-gold", $color-accent)
@include register_wp_color("vanway-primary", $color-base)
@include register_wp_color("white", #fff)
@include register_wp_color("black", #000)

