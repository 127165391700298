$color-base: #112438;
$color-accent: #f3a000;

$color-text-standard: #222;
$color-text-light: #747474;
$color-text-super-light: #a9a9a9;

$color-text-inverted-standard: #fff;
$color-text-inverted-little-dark: #efefef;
$color-text-inverted-dark: #cfcfcf;
$color-text-inverted-super-dark: #a9a9a9;

$color-background-desktop-submenu: #fff;

// Top menu
$color-top-menu-separator: #495867;
$color-top-menu-font: #fff;
$color-top-menu-background: $color-base;

$color-top-menu-submenu-separator: #d2d2d2;
$color-top-menu-submenu-font: #393939;
$color-top-menu-submenu-background: #f9f9f9;
