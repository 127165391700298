@import "../colors";
@import "../dimens";

.main-content .all-categories.alignwide {

  margin: 40px auto;

  p.select-category-text {
    padding-bottom: 20px;
    color: $color-text-light;
    font-size: 14px;
  }
}

div.post-categories {
  display: flex;
  gap: 12px;
}

div.post-categories,
div.all-categories {

  %active-category {
    border-color: $color-base;
    color: $color-base;
  }

  > ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 12px;
    list-style: none;
    padding: 0;
    margin-bottom: 0;

    li {
      padding: 0;

      &.current-cat a {
        @extend %active-category;
      }

      &::before {
        content: none;
      }
    }
  }

  > ul a,
  a.category-link {
    white-space: nowrap;
    text-transform: lowercase;
    display: inline-block;
    font-size: 16px;
    padding: 8px 16px;
    color: $color-text-light;
    border: 1px solid $color-text-light;
    border-radius: $border-radius;
    font-weight: 400;
    transition: all .3s ease;

    &:hover {
      background-color: rgba($color-base, .05);
    }

    &:hover,
    &.active {
      @extend %active-category;
    }
  }
}
