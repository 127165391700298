@import "../colors";
@import "../dimens";

.button-base {
  position: relative;
  display: inline-block;
  padding: 12px 24px;
  border-radius: $border-radius;
  transition: all .3s ease;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.button-full-width {
  width: 100%;
}

.button-primary {
  @extend .button-base;

  color: $color-text-inverted-standard;
  background: $color-base;
  border: 2px solid $color-base;

  &:hover {
    color: $color-text-inverted-standard;
    background-color: $color-base;
  }

  &.button-inverted {
    color: $color-base;
    background: $color-text-inverted-standard;
    border-color: $color-text-inverted-standard;

    &:hover {
      color: $color-base;
      background: $color-text-inverted-standard;
      border-color: $color-text-inverted-standard;
    }
  }
}

.button-secondary {
  @extend .button-base;

  color: $color-base;
  background: none;
  border: 2px solid $color-base;

  &:hover {
    color: $color-base;
    background-color: rgba($color-base, .15);
  }

  &.button-inverted {
    color: $color-text-inverted-standard;
    border-color: $color-text-inverted-standard;

    &:hover {
      color: $color-text-inverted-standard;
      background-color: rgba($color-text-inverted-standard, .15);
    }
  }
}

.wp-block-button {

  .wp-block-button__link,
  .wp-block-button__link.has-background {
    @extend .button-primary;
  }

  &.inverted {
    .wp-block-button__link,
    .wp-block-button__link.has-background {
      @extend .button-inverted;
    }
  }

  &.secondary {
    .wp-block-button__link,
    .wp-block-button__link.has-background {
      @extend .button-secondary;
    }

    &.inverted {
      .wp-block-button__link,
      .wp-block-button__link.has-background {
        @extend .button-secondary.button-inverted;
      }
    }
  }
}
