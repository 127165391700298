@import "../colors";
@import "../dimens";

.main-content .article {

  padding: 30px 0;

  .article-thumbnail {
    position: relative;

    img {
      position: absolute;
      border-radius: 3px;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100%;
      max-width: none;
      max-height: none;
      object-fit: cover;
      outline: none;
      border: none;
      box-shadow: none;
    }
  }

  h1.article-title {
    margin: 0;
    padding: 0;
    font-size: 24px;
    color: $color-text-standard;
  }

  div.article-content-wrapper {
    padding-left: 40px;
  }

  div.article-content {

    margin-top: 16px;

    p {
      font-size: 18px;
      color: $color-text-light;
    }
  }

  a.article-link {
    display: block;
    position: relative;

    &::before {
      content: '';
      width: 6px;
      background: $color-accent;
      position: absolute;
      top: 0;
      bottom: 0;
      left: -50px;
      transform: scaleY(0);
      transform-origin: top center;
      transition: all ease .3s;
    }

    .article-arrow img {
      transition: all ease .3s;
    }

    &:hover .article-arrow img {
      filter: invert(64%) sepia(81%) saturate(2551%) hue-rotate(6deg) brightness(102%) contrast(101%);
      transform: translateX(5px);
    }

    &:hover::before {
      transform: scaleY(1);
    }
  }

  div.article-footer {
    font-size: 16px;
    color: $color-text-super-light;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .article-arrow {
      color: $color-text-standard;
    }
  }
}
