@import "../colors";

.sharing-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 24px;

  a.sharing-icon {
    color: $color-text-standard;
    font-size: 22px;
    transition: all .2s ease;

    &:hover {
      transform: scale(1.25);
      color: $color-text-light;
    }
  }
}
