@import "colors";
@import "utils";

footer {
  margin-top: auto;
  color: $color-text-inverted-standard;
  background: $color-base;
  font-size: 16px;

  .footer-logo-column {
    display: flex;
    flex-direction: column;
  }

  .footer-icons {
    display: flex;
    flex-direction: row;
    gap: 35px;
    margin: 23px 0;

    a {
      font-size: 20px;
      color: $color-text-inverted-dark;
      transition: all .3s ease;

      &:hover {
        color: $color-accent;
        transform: scale(1.25);
      }
    }
  }

  .footer-copyright {
    color: $color-text-inverted-super-dark;
    font-size: 12px;

    p {
      padding: 0;
      margin: 0;
    }
  }

  .footer-widget {
    p.header {
      color: $color-text-inverted-standard;
      font-weight: bold;
      padding: 0;
      margin: 0;
      font-size: 18px;
    }

    ul {
      padding: 0;
      list-style: none;

      li a {
        display: inline-block;
        padding: 3px 0;
        margin: 3px 0;
        color: $color-text-inverted-little-dark;

        &:hover {
          color: $color-accent;
        }
      }
    }
  }
}

@include desktop() {
  footer {
    padding: 70px 0;

    .footer-widget {

      ul {
        margin: 40px 0 0 0;
      }
    }

    .footer-logo-section {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-width: 130px;
      flex: 1;
    }

    .widget-header-expand-collapse-wrapper {
      & > .expand,
      & > .collapse {
        display: none;
      }
    }
  }
}

@include mobile() {
  footer {
    padding: 0;

    .footer-widget {
      p.header {
        padding: 12px 0;
        margin: 0;
      }

      ul {
        margin: 8px 0;
      }
    }

    .container {
      padding: 0;
    }

    .footer-widget {
      padding: 0 16px;
      border-top: 1px solid #666;

      &:last-child {
        border-bottom: 1px solid #666;
      }

      .widget-header-wrapper {
        display: flex;
        flex-direction: row;
        align-content: center;
        justify-content: space-between;
        align-items: center;

        .widget-header-expand-collapse-wrapper {
          .collapse {
            display: none;
          }
          .expand {
            display: block;
          }
        }
      }

      // Widget content container
      & > div > div {
        overflow: hidden;
      }

      ul {
        margin-top: -100%;
        transition: all ease .3s;
      }

      &.expanded {

        ul {
          margin-top: 0;
        }

        .widget-header-expand-collapse-wrapper {
          .collapse {
            display: block;
          }
          .expand {
            display: none;
          }
        }
      }
    }

    .footer-logo-section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 24px;
      padding: 32px 0;

      .footer-logo-section-item {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;
      }

      .item-logo img {
        margin: 24px;
        max-width: 100px;
      }

      .item-downloads {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;
      }
    }

    .footer-mobile-bottom {
      padding: 0 16px;

      .footer-copyright {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
}
